/**
 * Get element data attributes
 * @param   {DOMElement}  node
 * @return  {Array}       data
 */
export function getNodeData(node) {
  // All attributes
  const attributes = node.attributes;

  // Regex Pattern
  const pattern = /^data\-(.+)$/;

  // Output
  const data = {};

  for (let i in attributes) {
      if (!attributes[i]) {
          continue;
      }

      // Attributes name (ex: data-module)
      let name = attributes[i].name;

      // This happens.
      if (!name) {
          continue;
      }

      let match = name.match(pattern);
      if (!match) {
          continue;
      }

      // If this throws an error, you have some
      // serious problems in your HTML.
      data[match[1]] = getData(node.getAttribute(name));
  }

  return data;
}

const rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/;

/**
 * Parse value to data type.
 *
 * @link   https://github.com/jquery/jquery/blob/3.1.1/src/data.js
 * @param  {string} data - A value to convert.
 * @return {mixed}  Returns the value in its natural data type.
 */
 export function getData(data) {
  if (data === 'true') {
      return true;
  }

  if (data === 'false') {
      return false;
  }

  if (data === 'null') {
      return null;
  }

  // Only convert to a number if it doesn't change the string
  if (data === +data+'') {
      return +data;
  }

  if (rbrace.test( data )) {
      return JSON.parse( data );
  }

  return data;
}