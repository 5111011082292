import { module } from 'modujs';
import anime from 'animejs/lib/anime.es.js';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      const origin = this.$('morph')[0].getAttribute("d");
      const shape = Array.from(this.$('shape')).map( e =>  e.getAttribute("d"));
      const randomDuration = (max, min = 5000) => Math.random() * (max - min) + min;

      anime({
        targets: this.$('morph')[0],
        d: [
          ...shape,
          origin,
        ],
        easing: 'easeInOutSine',
        direction: 'infinite alternate',
        duration: randomDuration,
        loop: true
      });
    }
}
