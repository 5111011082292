import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      this.scroll = new LocomotiveScroll({
        el: this.el,
        smooth: true,
        offset: ["10%", 0]
        // getDirection: true
      });

      this.scroll.on('call', (func, way, obj, id) => {
        // Using modularJS
        // console.log('func', func);
        // console.log('way', way);
        // console.log('obj', obj);
        // console.log('id', id);
        this.call(func[0], { way, obj }, func[1], func[2]);

        // this.call(...func);
      });

      this.scroll.on('scroll',  (args) => {
          if (args.scroll.y > document.body.clientHeight * 0.25) {
            html.classList.add('has-scroll');
          } else {
            html.classList.remove('has-scroll');
          }
        });
    }

    toggleLazy(args) {
      let src = this.getData('lazy', args.obj.el)
      if (src.length) {
          if (args.obj.el.tagName === 'IMG') {
              args.obj.el.src = src
          } else {
              args.obj.el.style.backgroundImage = `url('${src}')`
          }
          this.setData('lazy', '', args.obj.el)
      }
  }

    scrollTo(elm) {
      this.scroll.scrollTo(elm);
    }

    refresh() {
      this.scroll.update();
    }

    destroy() {
      this.scroll.destroy();
    }
}