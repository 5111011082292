// @ts-nocheck
import { module } from 'modujs';
import emblaCarousel from 'embla-carousel';
import gsap from 'gsap';

import { getNodeData } from '../utils/html';


export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            element: 'onSlideClick',
          }
      }

      this.waitForAnimation = false;

    }

    handleAnimations(node1, node2) {
      const timeline = gsap.timeline();

      timeline.to(
        node1,
        {
          duration: 0.5,
          yPercent: -100,
          ease:"power4.in",
          onStart: () => this.waitForAnimation = true,
          onComplete: () => node1.remove()
        }, "start"
      )
      .from(
        node2, {
          duration: 0.75,
          y: 110,
          opacity: 0,
          skewY: 7,
          ease:"power4.out",
          onComplete: () => this.waitForAnimation = false,
        }
        , "end"
      )
    }

    onSlideSelect(eventName) {
      const previous = this.embla.previousScrollSnap();
      const selected = this.embla.selectedScrollSnap();

      if( this.waitForAnimation ) {
        this.embla.scrollTo(previous);
        return;
      }

      const slide = this.embla.slideNodes()[selected];

      const name = getNodeData(slide).title;
      const url = getNodeData(slide).url;

      const newSpan = document.createElement('span');
      newSpan.textContent = name;

      this.el.querySelector('.test_name').appendChild(newSpan);
      this.el.querySelector('a').href = url

      this.handleAnimations(this.el.querySelector('.test_name span'), newSpan)
    }

    onSlideClick(e) {
      if( !this.embla.clickAllowed() ) {
        e.stopPropagation();
        e.preventDefault();
      }
    }

    init() {

      this.embla = emblaCarousel(this.$('viewport')[0], {
        align: "start",
        loop: true,
        // skipSnaps: false,
        // draggable: false,
      });

      this.embla.on('select', (eventName) => this.onSlideSelect(eventName) );
      // this.embla.on('init', (eventName) => this.onSlideSelect(eventName) );

      this.$('prev')[0].addEventListener('click', this.embla.scrollPrev, false);
      this.$('next')[0].addEventListener('click', this.embla.scrollNext, false);
    }
}
