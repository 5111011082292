const Form = {

  sendData: (form, formId) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData(form);

    form.querySelector('button')?.classList.add('btn-is-sending');

    // Define what happens on successful data submission
    xhr.addEventListener( "load", function(event) {

      form.reset();
      const elements = form.elements;

      form.querySelectorAll('.has-value').forEach((elements) => elements.classList.remove('has-value'));

      form.querySelector('button')?.classList.remove('btn-is-sending');
      form.querySelector('button')?.classList.add('btn-is-completed');
      setTimeout(function() {
        form.querySelector('button')?.classList.remove('btn-is-completed');
      }, 5550);
    } );

    // Define what happens in case of error
    xhr.addEventListener( "error", function( event ) {
      alert( 'Oops! Something went wrong.' );
    } );

    // Set up our request
    // @ts-ignore
    xhr.open( "POST", settings.ajaxurl );

    formData.append('action', formId);
    xhr.send( formData );
  },

  init: (formId) => {

    const form = document.getElementById(formId);
    console.log(form);

    const elements = form.elements;


    form.addEventListener( "submit",  ( e ) => {
      e.preventDefault();

      Form.sendData(form, formId);
    });


    for (let i=0; i < elements.length; i++){
      elements[i].addEventListener( "focus", (e) => {
        e.target.closest("p").classList.add('has-focus');
      });

      elements[i].addEventListener( "focusout", (e) => {
        e.target.closest("p").classList.remove('has-focus');
      });

      elements[i].addEventListener( "blur", (e) => {
        console.log('blur!!');
        if(e.target.value){
          console.log('has value');
          e.target.closest("p").classList.add('has-value');
        } else {
          e.target.closest("p").classList.remove('has-value');
        }
      });
    }
  }
}

export default Form;