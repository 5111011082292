// @ts-nocheck
import { module } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment';
import globals from '../globals';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.load = new modularLoad({
            enterDelay: 1000,
            readyClass: 'is-load-ready',
            transitions: {
                customTransition: {}
            }
        });

        this.load.on('loading', (transition, oldContainer) => {
          if(document.activeElement) document.activeElement.blur()

          html.classList.remove('is-ready');

          this.call('scrollTo', 0, 'Scroll');

          if( html.classList.contains('-show-menu') ) {
            this.call('toggleMenu', null, 'Menu');
          }
        });

        this.load.on('ready', () => {
          setTimeout(() => {
            html.classList.add('is-ready');
            this.call('refresh', null, 'Scroll');
          }, 300);

        });

        this.load.on('images', () => {
          setTimeout(() => {
            this.call('refresh', null, 'Scroll');
          }, 300);
        });

        this.load.on('loaded', async (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            this.call('update', null, 'Nav');

            globals();
        });

    }
}
