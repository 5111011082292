// @ts-nocheck
import { module } from 'modujs';
import emblaCarousel from 'embla-carousel';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
              header: 'toggleSection'
          }
        }
    }

    init() {
    }

    toggleSection(e) {
      const target = e.curTarget;
      const section = this.parent('section', target);


        if (this.$('section.is-open').length) {
          this.$('section.is-open')[0].querySelector('.c-accordion_body').style.maxHeight = '';
          this.$('section.is-open')[0].classList.remove('is-open');
        }

        section.classList.add('is-open');

        section.querySelector('.c-accordion_body').style.maxHeight = `${section.querySelector('.c-accordion_body > div').clientHeight}px`;

        this.call('scrollTo', {target: section}, 'Scroll');

      setTimeout(() => {
        this.call('refresh', 'Scroll');
      }, 100);

    }
}
