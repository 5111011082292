import { module } from 'modujs';
import { lerp } from '../utils/maths';
import { getMousePos } from '../utils/mouse';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        this.mouse = {x: 0, y: 0};
        this.scrollY = 0;

        this.bounds = this.$('stroke')[0].getBoundingClientRect();

        this.renderedStyles = {
          tx: {previous: 0, current: 0, amt: 0.2},
          ty: {previous: 0, current: 0, amt: 0.2},
          radius: {previous: 60, current: 60, amt: 0.2}
        };
    }

    enter() {
      this.renderedStyles['radius'].current = 35;
    }

    leave() {
      this.renderedStyles['radius'].current = 60;
    }

    bind() {
      [...document.querySelectorAll('a, button')].forEach(el => {
        el.addEventListener('mouseenter', () => this.enter());
        el.addEventListener('mouseleave', () => this.leave());
    });
    }

    init() {
      console.log('init');
      this.el.style.opacity = 0;

      window.addEventListener('mousemove', ev =>  this.mouse = getMousePos(ev));

      this.onMouseMoveEv = () => {
        this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.bounds.width/2;
        this.renderedStyles.ty.previous = this.renderedStyles.ty.current = (this.mouse.y + this.scrollY) - this.bounds.height/2;

        gsap.to(this.el, {duration: 0.9, ease: 'Power3.easeOut', opacity: 1});

        requestAnimationFrame(() => this.render());
        window.removeEventListener('mousemove', this.onMouseMoveEv);
    };


      window.addEventListener('mousemove', this.onMouseMoveEv);
      this.bind();
    }

    render() {
      this.renderedStyles['tx'].current = this.mouse.x - this.bounds.width/2;
      this.renderedStyles['ty'].current = (this.mouse.y + this.scrollY) - this.bounds.height/2;

      for (const key in this.renderedStyles ) {
        this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
      }

      this.$('stroke')[0].style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px)`;
      this.$('stroke')[0].querySelector('circle').setAttribute('r', this.renderedStyles['radius'].previous);

    requestAnimationFrame(() => this.render());
    }
}
