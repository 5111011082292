import * as modules from './js/modules';
import modular from 'modujs';
import { html, body } from './js/utils/environment';
import globals from './js/globals';

import './app.css';

const app = new modular({
  modules: modules
});

html.classList.remove('has-no-js');
html.classList.add('has-js');
html.classList.add('dom-is-loading');

window.onload = (event) => {
  init();
};

function init() {

  globals();
  app.init(app);

  html.classList.remove('dom-is-loading');
  html.classList.remove('is-loading');
  html.classList.add('dom-is-ready');

  setTimeout(() => {
    html.classList.add('is-ready');
    html.classList.add('is-loaded');
  }, 300);

  console.info('🚀 Ready to fly');
}