import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            toggle: 'toggleMenu'
          },
      }
    }

    setAnchorLink() {
      alert('setAnchorLink');
    }

    triggerMenuElement() {
      console.log('triggerMenuelement');
      const overlay = document.body.querySelector('.c-menu_overlay');

      overlay.addEventListener('click', e => {
        if(e.target === e.currentTarget) {
          this.call('toggleMenu', null, 'Menu');
        }
      });

      const links = document.body.querySelectorAll('[data-anchor]');

      links.forEach(link => {
        const anchor = link.dataset.anchor;
        const isHome = html.dataset.page === 'page';

        if(isHome) {
          link.setAttribute("href", '#');
          link.addEventListener('click', event => {

            history.pushState(null, null, anchor);
            this.call('scrollTo', `${anchor}`, 'Scroll');

            if( link.classList.contains('c-menu_overlay_link')) {
              this.toggleMenu();
            }
            event.preventDefault();
          })
        }
      });
    }

    init() {
      this.triggerMenuElement();
    }

    toggleMenu() {
      html.classList.toggle('-show-menu', !html.classList.contains('-show-menu'));
    }
}
