import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      this.el.querySelector('button').addEventListener("click", () => {
        this.call('scrollTo', 0, 'Scroll');
      })
    }
}
