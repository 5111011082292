import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.button = [];

        this.events = {
          click: {
            section: 'scrollToSection'
          }
        }
    }

    update() {
      this.$('container')[0].innerHTML = "";
      this.button = [];
      this.init();
    }

    init() {
      for(const section of Array.from(this.$('section'))) {
        const a = document.createElement("button");
          a.className = 'c-nav_link';

          this.$('container')[0].appendChild(a);

          this.button.push(a);
          a.addEventListener( 'click', (evt) =>  this.scrollToSection(evt) );
      }
    }

    setActive(args) {
      const index = Array.from(this.$('section')).indexOf(args.obj.el);
      // this.button.map(x => x.classList.remove('is-active'));

      if( args.way === "enter") {
        this.button[index].classList.add('is-active');
      } else if(args.way === "exit") {
      this.button[index].classList.remove('is-active');
      } else {
      }
    }

    scrollToSection(evt) {
      const index = this.button.indexOf(evt.target);
      this.call('scrollTo', this.$('section')[index], 'Scroll')
    }
}
