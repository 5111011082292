import { module } from 'modujs';
import { gsap, Power2 } from "gsap";

export default class extends module {
    constructor(m) {
        super(m);
    }

    updatePosition(e) {

      const movement = 85;

      let boundingRect = this.el.getBoundingClientRect();
      let relX = e.clientX - boundingRect.left;
      let relY = e.clientY - boundingRect.top;


      gsap.to(this.el.querySelector('a'), {
        duration:  0.3,
        x: (relX - boundingRect.width / 2) / boundingRect.width * movement,
        y: (relY - boundingRect.height / 2) / boundingRect.height * movement,
        ease: Power2.easeOut
    });

    }

    init() {
      this.el.querySelector('a').addEventListener("mousemove", (e) => this.updatePosition(e))
    }
}
