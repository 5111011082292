import { module } from 'modujs';
import { html } from '../utils/environment';
import Form from '../form'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        Form.init(this.el.id);
    }
}
