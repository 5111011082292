import { module } from 'modujs';
import emblaCarousel from 'embla-carousel';

export default class extends module {
    constructor(m) {
        super(m);
    }

    disablePrevNextBtns() {
      if( this.$('prev').length === 0 || this.$('next').length === 0) {
        return;
      }

      if (this.embla.canScrollPrev()) {
        this.$('prev')[0].removeAttribute('disabled');
      } else {
        this.$('prev')[0].setAttribute('disabled', 'disabled');
      }

      if (this.embla.canScrollNext()) {
        this.$('next')[0].removeAttribute('disabled');
      } else {
        this.$('next')[0].setAttribute('disabled', 'disabled');
      }

    }

    startVideo() {
      // Trigger fake click?

      document.documentElement.click();
      this.el.querySelector('video').play();
    }

    updatePager() {
      const currentSnap = this.embla.selectedScrollSnap();
      this.$('pager').forEach((el) => el.classList.remove('is-active'));
      this.$('pager')[currentSnap].classList.add("is-active");

      this.el.querySelector('.current').textContent = (currentSnap + 1);
    }

    checkResize() {
      let isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

      if(isMobile) {
        if( this.embla !== null ) {
          this.embla.destroy();
          this.embla = null;
        }
        return;
      }

      if( this.embla === null ){
        this.startCarousel();
      }

      this.embla.slideNodes().map( (e) => {
        e.style.height = `auto`;
        e.style.height = `${e.clientHeight}px`;
      } );

      const heights = this.embla.slideNodes().map( (e) => e.offsetHeight );
      const maxHeight = Math.max.apply(null, heights);

      this.embla.containerNode().style.height = `${maxHeight}px`;
      this.embla.slideNodes().map( (e) => e.style.height = `${maxHeight}px` );

      this.embla.reInit();
    }

    startCarousel() {
      this.embla = emblaCarousel(this.$('viewport')[0], {
        skipSnaps: false,
        axis: "y"
      });

      this.embla.on('init', () => this.checkResize() );
      this.embla.on('init', () => this.disablePrevNextBtns() );

      this.embla.on('select', () => this.updatePager() );
      this.embla.on('select', () => this.disablePrevNextBtns() );

      this.el.querySelector('.prev').addEventListener('click', this.embla.scrollPrev, false);
      this.el.querySelector('.next').addEventListener('click', this.embla.scrollNext, false);
    }

    init() {
      this.embla = null;
      this.checkResizeBind = this.checkResize.bind(this);
      window.addEventListener('resize', this.checkResizeBind);

      this.checkResizeBind();
    }

    destroy() {
      window.removeEventListener('resize', this.checkResizeBind);

      if( this.embla !== null ) {
        this.embla.destroy();
      }
    }
}
