// @ts-nocheck
import { module } from 'modujs';
import emblaCarousel from 'embla-carousel';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      this.embla = emblaCarousel(this.$('viewport')[0], {
        align: "center",
        loop: true,
        skipSnaps: false
      });

      this.embla.on('select', () => this.updatePager() );

      this.el.querySelector('.prev').addEventListener('click', this.embla.scrollPrev, false);
      this.el.querySelector('.next').addEventListener('click', this.embla.scrollNext, false);
    }

    updatePager() {
      const currentSnap = this.embla.selectedScrollSnap();
      this.el.querySelector('.current').textContent = (currentSnap + 1);
    }
}
